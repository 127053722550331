<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <!-- <div title="课程要求总学时" class="searchboxItem">
          <span class="itemLabel">课程要求总学时:</span>
          <span>{{50}}</span>
        </div>-->
      </div>
      <div class="btnBox" v-if="!flag">
        <el-button type="primary" class="bgc-bv" round @click="handleAdd()"
          >添加直播课程</el-button
        >
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="直播课程名称"
            align="left"
            show-overflow-tooltip
            prop="liveCourseName"
          />
          <el-table-column
            label="直播课数量"
            align="right"
            show-overflow-tooltip
            prop="lessonNum"
            width="100"
          />
          <el-table-column
            label="开始时间"
            align="center"
            show-overflow-tooltip
            prop="startTime"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.startTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="结束时间"
            align="center"
            show-overflow-tooltip
            prop="endTime"
            width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.endTime | moment }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100px">
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                @click="
                  see(scope.row.liveCourseId)
                "
                >查看</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding:0 5px"
                v-if="!flag"
                :disabled="
                  projectState == '20' || projectState == '30' || projectState == '50' 
                "
                @click="handleDel(scope.row.liveProjectCourseId)"
                >删除</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <livepopupCourse ref="livepopupCourse" @eventBus="tableBack" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import livepopupCourse from "@/views/liveBroadcastManagement/livePopup/livepopupCourse";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "myresourseList",
  props:['flag'],//运营端标识判断
  components: {
    Empty,
    PageNum,
    livepopupCourse,
  },
  mixins: [List],
  data() {
    return {
      delectId: "",
      userJson: {},
      queryStr: "",
      activeName: "first",
      ruleForm: {
        source: "",
        Auditstatus: "",
        Salesstatus: "",
        Trainingtype: "",
        Creattime: "",
      },
      liveProjectId: "",
      affirm: 0,
      countdown: "10",
      timer: null,
    };
  },
  computed: {},
  methods: {
    init() {
      this.liveProjectId = sessionStorage.getItem("liveProjectId");
      this.projectState = sessionStorage.getItem("projectState");
      this.getTableHeight();
      this.getData(-1);
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        liveProjectId: this.liveProjectId,
      };
      this.doFetch(
        {
          url: "/liveProject/live-project/coursePageList",
          params,
          pageNum,
        },
        true,
        3
      );
    },
    see(liveCourseId) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassCourseDetails",
        query: {
          liveCourseId,
          flag:this.flag
        },
      });
    },
    // 创建课程、查看编辑课程
    handleAdd() {
      if (this.tableData.length == 1) {
        this.$confirm("直播课程，最多只能添加1个。", "添加直播课程", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            return;
          })
          .catch(() => {
            return;
          });
      } else {
        this.$refs.livepopupCourse.showPopUp(this.liveProjectId);
      }
    },
    // 删除
    handleDel(liveProjectCourseId) {
      this.doDel({
        url: "/liveProject/live-project/delProjectCourse",
        msg: "你确定要删除该课程吗？",
        ps: {
          type: "post",
          data: { liveProjectCourseId }
        }
      },true,3);
    },
    //回调
    tableBack() {
      this.getData();
      // this.cancle();
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }
  .el-dialog__title {
    color: #333;
  }
  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
</style>
