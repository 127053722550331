<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="添加直播课程"
    top="1%"
    width="90%"
    :center="true"
    :before-close="dialogClose"
  >
    <div>
      <section class="">
         <div class="operationControl">
          <div class="searchbox">
            <div title="直播课程名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem"
                >直播课程名称:</span
              >
              <el-input
                size="small"
                v-model="liveCourseName"
                type="text"
                placeholder="请输入直播课程名称"
                clearable
              />
            </div>
        
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column label width="30px" align="center" >
                <template slot-scope="scope">
                  <el-radio
                    v-model="radio"
                    :label="scope.row.liveCourseId"
                    @change="radioChange(scope.row)"
                    :checked="radio == scope.row.liveCourseId ? true : false"
                  />
                </template>
              </el-table-column>
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="直播课程名称"
                align="left"
                show-overflow-tooltip
                prop="liveCourseName"
                minWidth="260"
              />
              <el-table-column
                label="直播课程数量"
                align="right"
                show-overflow-tooltip
                prop="lessonNum"
              />
              <el-table-column
                label="开始时间"
                align="right"
                show-overflow-tooltip
                prop="startTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="right"
                show-overflow-tooltip
                prop="endTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </section>
    </div>
    <span class="dialog-footer">
      <div class="dialogBtn">
        <el-button @click="dialogClose">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "course",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      value2: [],
      dialogVisible: false,
      radio: "",
      props: {
        value: "id",
        label: "label",
      },
      liveCourseName:'',
    };
  },

  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if(this.liveCourseName) {
        params.liveCourseName = this.liveCourseName
      }
      this.doFetch(
        {
          url: "/liveProject/live-project/addCourseList",
          params,
          pageNum,
        },
        true,
        3
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.5) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    radioChange(row) {
      this.liveCourseId = row.liveCourseId;
    },
    doOk() {
      if (!this.radio) {
        this.$message({
          message: "请选择直播课程",
          type: "warning",
        });
        return false;
      } else {
        const parmar = {
          liveProjectId: this.liveProjectId,
          liveCourseId: this.liveCourseId,
        };
        this.$post(
          "/liveProject/live-project/addProjectCourse",
          parmar,
          3000,
          true,
          3
        )
          .then((ret) => {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.dialogClose();
            this.$emit("eventBus");
          })
          .then((err) => {
            return;
          });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    showPopUp(liveProjectId) {
      this.dialogVisible = true;
      this.liveProjectId = liveProjectId;
      this.getData(1);
    },
  },
};
</script>
<style lang="less">
.CourseTableBox {
  display: flex;
  justify-content: space-between;
  height: 600px;
  .paperMation {
    width: 35%;
    border: 1px solid #5c6be8;
    padding: 20px;
    border-radius: 0.5rem;
    .paperMationDetail {
      padding: 20px 0 0 0;
    }
  }
}
.CourseTable {
  width: 63%;
  border: 1px solid #5c6be8;
  padding: 10px;
  border-radius: 0.5rem;
  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}
.testTime {
  // margin-left: 180px;
  padding-top: 20px;
  .itemLabel {
    display: inline-block;
    width: 130px;
    padding-bottom: 0.75rem;
    line-height: 2;
  }
  .testFomation {
    display: block;
    width: 300px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dialogBtn {
  button {
    width: 10rem;
  }
}
</style>
